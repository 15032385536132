import { LoadingDots } from '@components/ui'
import { FacetLabelMapping, Swatch, slice } from '@lib/prismic'
import dynamic from 'next/dynamic'
import { Fragment, Suspense } from 'react'

const dynamicImportOptions = { suspense: true }

const DynamicFullSizeBanner = dynamic(() => import('@components/slices/FullSizeBanner'), {
  ...dynamicImportOptions,
})
const DynamicBannerImageOnly = dynamic(() => import('@components/slices/BannerImageOnly'), {
  ...dynamicImportOptions,
})
const DynamicBanner = dynamic(() => import('@components/slices/Banner'), {
  ...dynamicImportOptions,
})
const DynamicBannerVideoSVG = dynamic(() => import('@components/slices/BannerVideoSVG'), {
  ...dynamicImportOptions,
})
const DynamicPLPFeatures = dynamic(() => import('@components/slices/PLPFeatures'), {
  ...dynamicImportOptions,
})
const DynamicPLPServices = dynamic(() => import('@components/slices/PLPServices'), {
  ...dynamicImportOptions,
})
const DynamicMoreCollections = dynamic(() => import('@components/slices/MoreCollections'), {
  ...dynamicImportOptions,
})
const DynamicSignatureStylesSlider = dynamic(() => import('@components/slices/SignatureStylesSlider'), {
  ...dynamicImportOptions,
})
const DynamicItemList = dynamic(() => import('@components/slices/ItemList'), {
  ...dynamicImportOptions,
})
const DynamicProductList = dynamic(() => import('@components/slices/ProductList'), {
  ...dynamicImportOptions,
})
const DynamicInstagramBlock = dynamic(() => import('@components/slices/InstagramBlock'), {
  ...dynamicImportOptions,
})
const DynamicSplitPanel = dynamic(() => import('@components/slices/SplitPanel'), {
  ...dynamicImportOptions,
})
const DynamicGoToFilters = dynamic(() => import('@components/slices/GoToFilters'), {
  ...dynamicImportOptions,
})
const DynamicSearchSuggestions = dynamic(() => import('@containers/Search/SearchSuggestions'), {
  ...dynamicImportOptions,
})
const DynamicFeaturedProducts = dynamic(() => import('@containers/Product/FeaturedProducts'), {
  ...dynamicImportOptions,
})
const DynamicScrollingTexts = dynamic(() => import('@components/slices/ScrollingTexts'), {
  ...dynamicImportOptions,
})
const DynamicIntroTexts = dynamic(() => import('@components/slices/IntroTexts'), {
  ...dynamicImportOptions,
})
const DynamicIntroTextsV2 = dynamic(() => import('@components/slices/IntroTextsV2'), {
  ...dynamicImportOptions,
})
const DynamicShopTheRanges = dynamic(() => import('@components/slices/ShopTheRanges'), {
  ...dynamicImportOptions,
})
const DynamicCollectionsList = dynamic(() => import('@components/slices/CollectionsList'), {
  ...dynamicImportOptions,
})
const DynamicKeyFeatures = dynamic(() => import('@components/slices/KeyFeatures'), {
  ...dynamicImportOptions,
})
const DynamicSplitQuarterPanel = dynamic(() => import('@components/slices/SplitQuarterPanel'), {
  ...dynamicImportOptions,
})
const DynamicSplitPanelCenter = dynamic(() => import('@components/slices/SplitPanelCenter'), {
  ...dynamicImportOptions,
})
const DynamicContent = dynamic(() => import('@components/slices/Content'), {
  ...dynamicImportOptions,
})
const DynamicActionButton = dynamic(() => import('@components/slices/ActionButton'), {
  ...dynamicImportOptions,
})
const DynamicTable2Column = dynamic(() => import('@components/slices/Table2Column'), {
  ...dynamicImportOptions,
})
const DynamicTable2ColumnV2 = dynamic(() => import('@components/slices/Table2ColumnV2'), {
  ...dynamicImportOptions,
})
const DynamicTable3Column = dynamic(() => import('@components/slices/Table3Column'), {
  ...dynamicImportOptions,
})
const DynamicTable3ColumnV2 = dynamic(() => import('@components/slices/Table3ColumnV2'), {
  ...dynamicImportOptions,
})
const DynamicQuotesBlock = dynamic(() => import('@components/slices/QuotesBlock'), {
  ...dynamicImportOptions,
})
const DynamicCTAButton = dynamic(() => import('@components/slices/CTAButton'), {
  ...dynamicImportOptions,
})
const DynamicFAQs = dynamic(() => import('@components/slices/FAQs'), {
  ...dynamicImportOptions,
})
const DynamicFancyLinks = dynamic(() => import('@components/slices/FancyLinks'), {
  ...dynamicImportOptions,
})
const DynamicSplitPanelV3 = dynamic(() => import('@components/slices/SplitPanelV3'), {
  ...dynamicImportOptions,
})
const DynamicJobListings = dynamic(() => import('@components/slices/JobListings'), {
  ...dynamicImportOptions,
})
const DynamicStoreDetails = dynamic(() => import('@components/slices/StoreDetails'), {
  ...dynamicImportOptions,
})
const DynamicGoogleMap = dynamic(() => import('@components/slices/GoogleMap'), {
  ...dynamicImportOptions,
})
const DynamicContentVideoBlock = dynamic(() => import('@components/slices/ContentVideoBlock'), {
  ...dynamicImportOptions,
})
const DynamicHeadingBlock = dynamic(() => import('@components/slices/HeadingBlock'), {
  ...dynamicImportOptions,
})
const DynamicCollectionRow = dynamic(() => import('@components/slices/CollectionRow'), {
  ...dynamicImportOptions,
})
const DynamicCollectionGrid = dynamic(() => import('@components/slices/CollectionGrid'), {
  ...dynamicImportOptions,
})
const DynamicLogoBlock = dynamic(() => import('@components/slices/LogoBlock'), {
  ...dynamicImportOptions,
})
const DynamicImmersiveBanner = dynamic(() => import('@components/slices/ImmersiveBanner'), {
  ...dynamicImportOptions,
})
const DynamicBreadcrumbSlice = dynamic(() => import('@components/slices/BreadcrumbSlice'), {
  ...dynamicImportOptions,
})
const DynamicReviews = dynamic(() => import('@components/slices/Reviews'), {
  ...dynamicImportOptions,
})
const DynamicNewsletter = dynamic(() => import('@components/slices/Newsletter'), {
  ...dynamicImportOptions,
})
const DynamicMultiPanel = dynamic(() => import('@components/slices/MultiPanel'), {
  ...dynamicImportOptions,
})
type SliceZoneProps = {
  slices: [slice: slice]
  colourSwatches?: Swatch[]
  facetLabelMappings?: FacetLabelMapping[]
  applyWrapper?: boolean
  widthSideMenu?: boolean
  plpDoc?: any
}

const SliceZone = ({
  slices,
  colourSwatches,
  facetLabelMappings,
  plpDoc,
  applyWrapper = true,
  widthSideMenu,
}: SliceZoneProps) => {
  return (
    <Suspense fallback={<LoadingDots />}>
      {slices?.map((slice: slice, index: number) => {
        const sliceType = slice.slice_type
        switch (sliceType) {
          case 'banner_image_only':
            return (
              <DynamicBannerImageOnly
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                applyWrapper={applyWrapper}
              />
            )
          case 'plp__banner':
          case 'banner': // full width banner does not support wrapper
            return <DynamicBanner primary={slice.primary} key={`${sliceType}-${index}`} />
          case 'banner_with_svg': // full width banner does not support wrapper
            return <DynamicBannerVideoSVG primary={slice.primary} key={`${sliceType}-${index}`} />
          case 'product_features':
            return (
              <DynamicPLPFeatures
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'service_usps_block':
            return (
              <DynamicPLPServices
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'more_collections':
            return (
              <DynamicMoreCollections
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'signature_styles_slider':
            return (
              <DynamicSignatureStylesSlider
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'item_list':
            return (
              <DynamicItemList
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'product_list':
            if (!plpDoc) return <Fragment key={`${sliceType}-${index}`}></Fragment>
            return (
              <DynamicProductList
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                colourSwatches={colourSwatches || []}
                facetLabelMappings={facetLabelMappings || []}
                applyWrapper={applyWrapper}
                plpDoc={plpDoc?.doc}
                searchState={plpDoc.searchState}
                resultsState={plpDoc.resultsState}
              />
            )
          case 'instagram_block':
            return (
              <DynamicInstagramBlock
                primary={slice.primary}
                key={`${sliceType}-${index}`}
                applyWrapper={applyWrapper}
              />
            )
          case 'split_panel':
            return <DynamicSplitPanel data={slice.primary} key={`${sliceType}-${index}`} applyWrapper={applyWrapper} />
          case 'tag_filters':
            return <DynamicGoToFilters key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'search_suggestions':
            return (
              <DynamicSearchSuggestions
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'featured_products':
            return <DynamicFeaturedProducts key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'scrolling_texts':
            return (
              <DynamicScrollingTexts
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                applyWrapper={applyWrapper}
              />
            )
          case 'intro_texts':
            return (
              <DynamicIntroTexts key={`${sliceType}-${index}`} primary={slice.primary} applyWrapper={applyWrapper} />
            )
          case 'intro_texts_v2':
            return (
              <DynamicIntroTextsV2
                key={`${sliceType}-${index}`}
                index={index}
                primary={slice.primary}
                applyWrapper={applyWrapper}
                items={slice.items}
              />
            )
          case 'shop_the_ranges':
            return <DynamicShopTheRanges primary={slice.primary} key={`${sliceType}-${index}`} items={slice.items} />
          case 'collections_list':
            return <DynamicCollectionsList primary={slice.primary} items={slice.items} key={`${sliceType}-${index}`} />
          case 'key_features':
            return <DynamicKeyFeatures primary={slice.primary} key={`${sliceType}-${index}`} items={slice.items} />
          case 'split_quarter_panel':
            return <DynamicSplitQuarterPanel primary={slice.primary} key={`${sliceType}-${index}`} />
          case 'split_panel_center':
            return <DynamicSplitPanelCenter primary={slice.primary} key={`${sliceType}-${index}`} />
          case 'content':
            return (
              <DynamicContent
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                applyWrapper={applyWrapper}
                width_side_menu={widthSideMenu}
              />
            )
          case 'action_button':
            return (
              <DynamicActionButton key={`${sliceType}-${index}`} primary={slice.primary} applyWrapper={applyWrapper} />
            )
          case 'table__2_column_':
            return (
              <DynamicTable2Column
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'table_2_column__v2':
            return (
              <DynamicTable2ColumnV2
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'table__3_column_':
            return (
              <DynamicTable3Column
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'table_3_column_v2':
            return (
              <DynamicTable3ColumnV2
                key={`${sliceType}-${index}`}
                primary={slice.primary}
                items={slice.items}
                applyWrapper={applyWrapper}
              />
            )
          case 'quote_block':
            return (
              <DynamicQuotesBlock key={`${sliceType}-${index}`} primary={slice.primary} applyWrapper={applyWrapper} />
            )
          case 'cta_button':
            return (
              <DynamicCTAButton key={`${sliceType}-${index}`} primary={slice.primary} applyWrapper={applyWrapper} />
            )
          case 'faq_section':
            return (
              <DynamicFAQs
                key={`${sliceType}-${index}`}
                items={slice.items}
                applyWrapper={applyWrapper}
                index={index}
              />
            )
          case 'fancy_links':
            return <DynamicFancyLinks key={`${sliceType}-${index}`} items={slice.items} applyWrapper={applyWrapper} />
          case 'split_panel_v3':
            return <DynamicSplitPanelV3 key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'job_listings':
            return <DynamicJobListings key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'store_details':
            return <DynamicStoreDetails key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'google_map':
            return <DynamicGoogleMap key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'content_video_block':
            return <DynamicContentVideoBlock key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'heading_block':
            return <DynamicHeadingBlock key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'collection_row':
            return <DynamicCollectionRow key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'collection_grid':
            return <DynamicCollectionGrid key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'logo_block':
            return <DynamicLogoBlock key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'immersive_banner':
            return <DynamicImmersiveBanner index={index} key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'breadcrumb':
            return <DynamicBreadcrumbSlice key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          case 'full_size_banner':
            return <DynamicFullSizeBanner key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'reviews':
            return <DynamicReviews key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'newsletter':
            return <DynamicNewsletter key={`${sliceType}-${index}`} primary={slice.primary} />
          case 'multi_panel':
            return <DynamicMultiPanel key={`${sliceType}-${index}`} primary={slice.primary} items={slice.items} />
          default:
            return ''
        }
      })}
    </Suspense>
  )
}

export default SliceZone
